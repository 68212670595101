import React, { useState, useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, onValue, off } from 'firebase/database';
import ProgressBar from "@ramonak/react-progress-bar";
import './style.css';

// Initialize Firebase
const firebaseConfig = {
    apiKey: "AIzaSyBOuu294zvRDZQVx2BLaVveXcsdDxpTjUA",
    authDomain: "rachpower-dad00.firebaseapp.com",
    databaseURL: "https://rachpower-dad00-default-rtdb.firebaseio.com",
    projectId: "rachpower-dad00",
    storageBucket: "rachpower-dad00.appspot.com",
    messagingSenderId: "364411865303",
    appId: "1:364411865303:web:7c4d04c39e6413eeb1c892"
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

const Modal = ({ device, onClose }) => {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        if (!device) return;

        setProgress(15); // Reset progress to 0
        let interval = setInterval(() => {
            setProgress((prev) => {
                if (prev >= 120) return 0; // Reset after completion
                return prev + 0.333; // Increment progress
            });
        }, 100); // Increment every 1 second (100% in 20 seconds)

        return () => clearInterval(interval); // Clean up interval on unmount or reset
    }, [device?.time_stamp]);

    if (!device) return null;

    const renderSignalBars = (signal) => {
        const heights = ['h-2', 'h-4', 'h-6', 'h-8', 'h-10'];
        const bars = [];
        for (let i = 1; i <= 5; i++) {
            bars.push(
                <div
                    key={i}
                    className={`${heights[i - 1]} w-1 ${i <= signal ? 'bg-green-700' : 'bg-gray-300'} mx-0.5`}
                ></div>
            );
        }
        return <div className="flex items-end">{bars}</div>;
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-slate-100 p-6 rounded-lg shadow-lg w-3/4 ">
                <ProgressBar
                    completed={progress}
                    bgColor="#16910B"
                    baseBgColor="#ddd"
                    height="20px"
                    isLabelVisible={true}
                    transitionDuration="0.1s"
                    className='mb-3 .vt323-regular w-3/4 mx-auto'
                    customLabel='Fetching Data...'
                    labelSize='12px'
                />
                <div className="flex justify-between items-center mb-4">
                    <div className="flex items-center">
                        {device.device === 1 ? (
                            <div className='text-white bg-red-600 font-semibold text-center rounded-lg shadow-xl px-5 py-3 p-1 mr-4'>Wifi Module</div>
                        ) : (
                            <div className='text-white font-semibold text-[14px] shadow-xl px-5 py-3 bg-yellow-500 text-center rounded-lg p-1 mr-4'>GSM Module</div>
                        )}
                        {renderSignalBars(device.signal)}
                    </div>
                    <h2 className="text-2xl font-bold text-[#28507B] border-b border-blue-800 w-[50%] text-center">LIVE PSU DEVICE DATA</h2>
                    <div className='text-slate-100'>X</div>
                    <button onClick={onClose} className="text-red-500 font-extrabold text-2xl">✕</button>
                </div>
                <div className="flex justify-between mb-4">
                    <table className="w-1/2 bg-white shadow-2xl rounded-lg mr-4">
                        <tbody>
                            <tr className="border-b">
                                <td className="py-2 px-4 font-semibold">Client</td>
                                <td className="py-2 px-4">{device.client}</td>
                            </tr>
                            <tr className="border-b">
                                <td className="py-2 px-4 font-semibold">Project</td>
                                <td className="py-2 px-4">{device.project}</td>
                            </tr>
                            <tr className="border-b">
                                <td className="py-2 px-4 font-semibold">Zone</td>
                                <td className="py-2 px-4">{device.zone}</td>
                            </tr>
                            <tr className="border-b">
                                <td className="py-2 px-4 font-semibold">Circle</td>
                                <td className="py-2 px-4">{device.circle}</td>
                            </tr>
                            <tr className="border-b">
                                <td className="py-2 px-4 font-semibold">Cluster</td>
                                <td className="py-2 px-4">{device.cluster}</td>
                            </tr>
                            <tr className="border-b">
                                <td className="py-2 px-4 font-semibold">City</td>
                                <td className="py-2 px-4">{device.city}</td>
                            </tr>
                            <tr className="border-b">
                                <td className="py-2 px-4 font-semibold">Site</td>
                                <td className="py-2 px-4">{device.site}</td>
                            </tr>
                            <tr className="border-b">
                                <td className="py-2 px-4 font-semibold">Site Id</td>
                                <td className="py-2 px-4">{device.siteId}</td>
                            </tr>
                            <tr className="border-b">
                                <td className="py-2 px-4 font-semibold">IMEI No</td>
                                <td className="py-2 px-4">{device.imei}</td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="w-1/2 bg-white shadow-2xl rounded-lg">
                        <tbody>
                            <tr className="border-b">
                                <td className="py-2 px-4 font-semibold">Voltage Leak</td>
                                <td className="py-2 px-4">{device.leak_volt < 30 ? device.leak_volt : <span>{device.leak_volt} <span className='text-red-500'>(Leak Voltage Warning)</span></span>}</td>
                            </tr>
                            <tr className="border-b">
                                <td className="py-2 px-4 font-semibold">Main Voltage</td>
                                <td className="py-2 px-4">{device.mains_volt < 600 ? device.mains_volt : <span>{device.mains_volt} <span className='text-red-500'>(Main Voltage Warning)</span></span>}</td>
                            </tr>
                            <tr className="border-b">
                                <td className="py-2 px-4 font-semibold">Temperature</td>
                                <td className="py-2 px-4">{device.temperature}</td>
                            </tr>
                            <tr className="border-b">
                                <td className="py-2 px-4 font-semibold">Mains Current</td>
                                <td className="py-2 px-4">{device.mains_current}</td>
                            </tr>
                            <tr className="border-b">
                                <td className="py-2 px-4 font-semibold">Mains Power</td>
                                <td className="py-2 px-4">{device.mains_power}</td>
                            </tr>
                            <tr className="border-b">
                                <td className="py-2 px-4 font-semibold">Power Factor</td>
                                <td className="py-2 px-4">{device.power_factor}</td>
                            </tr>
                            <tr className="border-b">
                                <td className="py-2 px-4 font-semibold">Battery Voltage</td>
                                <td className="py-2 px-4">{device.battery_voltage}</td>
                            </tr>
                            <tr className="border-b">
                                <td className="py-2 px-4 font-semibold">Battery Current</td>
                                <td className="py-2 px-4">{device.current}</td>
                            </tr>
                            <tr className="border-b">
                                <td className="py-2 px-4 font-semibold">Heat Index</td>
                                <td className="py-2 px-4">{device.heat_index}</td>
                            </tr>
                            <tr className="border-b">
                                <td className="py-2 px-4 font-semibold">Humidity</td>
                                <td className="py-2 px-4">{device.humidity}</td>
                            </tr>
                            <tr className="border-b">
                                <td className="py-2 px-4 font-semibold">Last Updated</td>
                                <td className="py-2 px-4">{device.lastUpdatedTime || 'N/A'}</td>
                            </tr>
                            <tr>
                                <td className="py-2 px-4 font-semibold">Update Count</td>
                                <td className="py-2 px-4">{device.updateCount || 0}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

const ManagePSUComponent = () => {
    const [clients, setClients] = useState([]);
    const [projects, setProjects] = useState([]);
    const [zones, setZones] = useState([]);
    const [circles, setCircles] = useState([]);
    const [clusters, setClusters] = useState([]);
    const [cities, setCities] = useState([]);
    const [sites, setSites] = useState([]);
    const [psuDevices, setPsuDevices] = useState([]);
    const [selectedClient, setSelectedClient] = useState("");
    const [selectedProject, setSelectedProject] = useState("");
    const [selectedZone, setSelectedZone] = useState("");
    const [selectedCircle, setSelectedCircle] = useState("");
    const [selectedCluster, setSelectedCluster] = useState("");
    const [selectedCity, setSelectedCity] = useState("");
    const [selectedSite, setSelectedSite] = useState("");
    const [imei, setImei] = useState("");
    const [selectedDevice, setSelectedDevice] = useState(null);

    useEffect(() => {
        fetchData('clients', setClients);
        fetchData('zones', setZones);
    }, []);

    useEffect(() => {
        if (selectedClient && selectedProject && selectedZone && selectedCircle && selectedCluster && selectedCity && selectedSite) {
            searchPsuDevices();
        }
    }, [selectedClient, selectedProject, selectedZone, selectedCircle, selectedCluster, selectedCity, selectedSite, imei]);

    useEffect(() => {
        if (selectedDevice) {
            const deviceRef = ref(database, `psuDevices/${selectedDevice.id}`);
            const lastUpdatedTimeRef = ref(database, `pastTime/${selectedDevice.id}/lastUpdatedTime`);
            const updateCountRef = ref(database, `pastTime/${selectedDevice.id}/updateCount`);

            const handleDeviceChange = (snapshot) => {
                const data = snapshot.val();
                if (data) {
                    setSelectedDevice(prev => ({ ...prev, ...data }));
                }
            };

            const handleLastUpdatedTimeChange = (snapshot) => {
                const lastUpdatedTime = snapshot.val();
                setSelectedDevice(prev => ({ ...prev, lastUpdatedTime }));
            };

            const handleUpdateCountChange = (snapshot) => {
                const updateCount = snapshot.val();
                setSelectedDevice(prev => ({ ...prev, updateCount }));
            };

            // Attach listeners
            const deviceListener = onValue(deviceRef, handleDeviceChange);
            const lastUpdatedTimeListener = onValue(lastUpdatedTimeRef, handleLastUpdatedTimeChange);
            const updateCountListener = onValue(updateCountRef, handleUpdateCountChange);

            // Cleanup listeners on component unmount or when selectedDevice changes
            return () => {
                off(deviceRef, 'value', handleDeviceChange);
                off(lastUpdatedTimeRef, 'value', handleLastUpdatedTimeChange);
                off(updateCountRef, 'value', handleUpdateCountChange);
            };
        }
    }, [selectedDevice]);


    const fetchData = async (path, setState) => {
        try {
            const response = await fetch(`https://rachpower-dad00-default-rtdb.firebaseio.com/${path}.json`);
            const data = await response.json();
            setState(data ? Object.entries(data).map(([key, value]) => ({ id: key, ...value })) : []);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const handleClientChange = async (e) => {
        const clientId = e.target.value;
        setSelectedClient(clientId);
        const client = clients.find(client => client.id === clientId);
        if (client && client.projects) {
            const projectsArray = Object.entries(client.projects).map(([key, value]) => ({ id: key, ...value }));
            setProjects(projectsArray);
        } else {
            setProjects([]);
        }
        searchPsuDevices();
    };

    const handleZoneChange = async (e) => {
        const zoneId = e.target.value;
        setSelectedZone(zoneId);
        const zone = zones.find(zone => zone.id === zoneId);
        if (zone && zone.circles) {
            const circlesArray = Object.entries(zone.circles).map(([key, value]) => ({ id: key, ...value }));
            setCircles(circlesArray);
        } else {
            setCircles([]);
        }
        searchPsuDevices();
    };

    const handleCircleChange = async (e) => {
        const circleId = e.target.value;
        setSelectedCircle(circleId);
        const circle = circles.find(circle => circle.id === circleId);
        if (circle && circle.clusters) {
            const clustersArray = Object.entries(circle.clusters).map(([key, value]) => ({ id: key, ...value }));
            setClusters(clustersArray);
        } else {
            setClusters([]);
        }
        searchPsuDevices();
    };

    const handleClusterChange = async (e) => {
        const clusterId = e.target.value;
        setSelectedCluster(clusterId);
        const cluster = clusters.find(cluster => cluster.id === clusterId);
        if (cluster && cluster.cities) {
            const citiesArray = Object.entries(cluster.cities).map(([key, value]) => ({ id: key, ...value }));
            setCities(citiesArray);
        } else {
            setCities([]);
        }
        searchPsuDevices();
    };

    const handleCityChange = async (e) => {
        const cityId = e.target.value;
        setSelectedCity(cityId);
        const city = cities.find(city => city.id === cityId);
        if (city && city.sites) {
            const sitesArray = Object.entries(city.sites).map(([key, value]) => ({ id: key, ...value }));
            setSites(sitesArray);
        } else {
            setSites([]);
        }
        searchPsuDevices();
    };

    const searchPsuDevices = async () => {
        try {
            const response = await fetch(`https://rachpower-dad00-default-rtdb.firebaseio.com/psuDevices.json`);
            const data = await response.json();
            const devicesArray = data ? Object.entries(data).map(([key, value]) => ({ id: key, ...value })) : [];
            const filteredDevices = devicesArray.filter(device =>
                (selectedClient === "" || device.client === clients.find(client => client.id === selectedClient)?.name) &&
                (selectedProject === "" || device.project === projects.find(project => project.id === selectedProject)?.name) &&
                (selectedZone === "" || device.zone === zones.find(zone => zone.id === selectedZone)?.name) &&
                (selectedCircle === "" || device.circle === circles.find(circle => circle.id === selectedCircle)?.name) &&
                (selectedCluster === "" || device.cluster === clusters.find(cluster => cluster.id === selectedCluster)?.name) &&
                (selectedCity === "" || device.city === cities.find(city => city.id === selectedCity)?.name) &&
                (selectedSite === "" || device.site === sites.find(site => site.id === selectedSite)?.siteName) &&
                (imei === "" || device.imei.includes(imei))
            );
            setPsuDevices(filteredDevices);
        } catch (error) {
            console.error("Error fetching PSU devices:", error);
        }
    };

    const openModal = (device) => {
        setSelectedDevice(device);
    };

    const closeModal = () => {
        setSelectedDevice(null);
    };

    return (
        <div className="p-4 bg-slate-50 h-screen">
            <div className=' p-4 rounded-lg bg-white shadow-xl'>
                <h1 className="text-xl font-semibold mb-4 text-[#28507B]">Manage PSU Devices</h1>
                <div className="grid grid-cols-3 gap-4">
                    {/* Form Fields */}
                    <div>
                        <label>Client</label>
                        <select value={selectedClient} onChange={handleClientChange} className="w-full border p-2 rounded">
                            <option value="">All Clients</option>
                            {clients.map(client => (
                                <option key={client.id} value={client.id}>{client.name}</option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label>Project</label>
                        <select value={selectedProject} onChange={(e) => setSelectedProject(e.target.value)} className="w-full border p-2 rounded">
                            <option value="">All Projects</option>
                            {projects.map(project => (
                                <option key={project.id} value={project.id}>{project.name}</option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label>Zone</label>
                        <select value={selectedZone} onChange={handleZoneChange} className="w-full border p-2 rounded">
                            <option value="">All Zones</option>
                            {zones.map(zone => (
                                <option key={zone.id} value={zone.id}>{zone.name}</option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label>Circle</label>
                        <select value={selectedCircle} onChange={handleCircleChange} className="w-full border p-2 rounded">
                            <option value="">All Circles</option>
                            {circles.map(circle => (
                                <option key={circle.id} value={circle.id}>{circle.name}</option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label>Cluster</label>
                        <select value={selectedCluster} onChange={handleClusterChange} className="w-full border p-2 rounded">
                            <option value="">All Clusters</option>
                            {clusters.map(cluster => (
                                <option key={cluster.id} value={cluster.id}>{cluster.name}</option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label>City</label>
                        <select value={selectedCity} onChange={handleCityChange} className="w-full border p-2 rounded">
                            <option value="">All Cities</option>
                            {cities.map(city => (
                                <option key={city.id} value={city.id}>{city.name}</option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label>Site</label>
                        <select value={selectedSite} onChange={(e) => setSelectedSite(e.target.value)} className="w-full border p-2 rounded">
                            <option value="">All Sites</option>
                            {sites.map(site => (
                                <option key={site.id} value={site.id}>{site.siteName}</option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label>IMEI</label>
                        <input
                            type="text"
                            value={imei}
                            onChange={(e) => setImei(e.target.value)}
                            className="w-full border p-2 rounded"
                        />
                    </div>
                </div>
                <div className="mt-4">
                    <button onClick={searchPsuDevices} className="bg-[#28507B] text-white px-8 py-2 rounded">Search</button>
                </div>
            </div>
            <div className="mt-8">
                <table className="min-w-full bg-white rounded-xl shadow-xl">
                    <thead className='bg-[#28507B] text-white'>
                        <tr>
                            <th className="py-2 font-semibold">Client</th>
                            <th className="py-2 font-semibold">Project</th>
                            <th className="py-2 font-semibold">Zone</th>
                            <th className="py-2 font-semibold">Circle</th>
                            <th className="py-2 font-semibold">Cluster</th>
                            <th className="py-2 font-semibold">City</th>
                            <th className="py-2 font-semibold">Site</th>
                            <th className="py-2 font-semibold">IMEI</th>
                            <th className="py-2 font-semibold">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {psuDevices.map(device => (
                            <tr key={device.id} className="text-center border-b">
                                <td className="py-2">{device.client}</td>
                                <td className="py-2">{device.project}</td>
                                <td className="py-2">{device.zone}</td>
                                <td className="py-2">{device.circle}</td>
                                <td className="py-2">{device.cluster}</td>
                                <td className="py-2">{device.city}</td>
                                <td className="py-2">{device.site}</td>
                                <td className="py-2">{device.imei}</td>
                                <td className="py-2">
                                    <button onClick={() => openModal(device)} className="bg-green-700 text-white px-6 py-1 rounded">View</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <Modal device={selectedDevice} onClose={closeModal} />
        </div>
    );
};

export default ManagePSUComponent;